body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

.am-list-view-scrollview-content {
  width: 100% !important;
}

.description-list .am-list-item .am-list-line .am-list-content {
  color: #888;
}
.description-list .am-list-item .am-list-line .am-list-extra {
  color: #000;
  flex-basis: auto;
}

.description-list .am-list-item .am-list-line.am-list-line-wrap .am-list-extra {
  flex-basis: 60%;
}

.custom-form .am-list-item .am-list-line .am-input-label,
.custom-form .am-list-item .am-list-line .am-list-content {
  color: #888;
}
.custom-form .am-list-item .am-list-line .am-input-control input {
  text-align: right;
}
.custom-form .am-list-item .am-list-line .am-list-extra {
  color: #000;
  flex-basis: 60%;
}
